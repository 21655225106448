<template>
	<div data-route>
		<page-header
			heading="My courses"
		/>
		<div data-element="main">
			<card-view
				:cards="getCards"
				:pagination="pagination"
				:totalItems="totalItems"
				:showSearch="false"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader                         from '@/components/ui/PageHeader';
	import CardView                           from '@/components/ui/views/cardView/CardView';
	import api                                from '@/services/api';

	export default {
		metaInfo: {
			title: 'My courses'
		},
		components: {
			PageHeader,
			CardView
		},
		data: () => ({
			courses: [],
			pagination: {
				perPage: 21,
				currentPage: 1,
				totalPages: 0
			},
			totalItems:  0
		}),
		computed: {
			getCourses () {
				if (!this.courses ||
						!this.courses.length) {
					return false;
				}
				return this.courses;
			},
			getCards () {
				if (!this.getCourses) {
					return [];
				};
				return this.getCourses.map((course) => {
					return {
						route: this.getCourseRoute(course),
						course
					};
				});
			},
			getBreadcrumbs () {
				return [
					{
						path: '/my-courses',
						text: 'My courses'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setCourses();
		},
		methods: {
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setCourses();
			},
			getCourseRoute (course) {
				return `/my-courses/${course.id}`;
			},
			async setCourses () {
				const response = await api.courses.getCurrentUsersCourses({
					include: 'metadata',
					searchString: '',
					page: this.pagination.currentPage,
					per: this.pagination.perPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.courses = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>
